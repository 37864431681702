import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import type {
	BellSchedule,
	BellScheduleGroup,
	PeriodTime,
	Schedule,
	ScheduleForDatesResponse,
	ScheduleGroupBellSchedule,
	ScheduleGroupList,
	UpdateBellScheduleForDateReq,
} from '../../models/Schedule';
import { isEqual } from 'lodash';
import { DatePipe } from '@angular/common';

type OnboardingStatus = 'processing' | 'in review' | 'pending' | 'active';

function findNextAvailableWeekdayAfterHoliday(startDate: string, arrayToCheckOverlap: { start_date: string; end_date: string }[]): string {
	const nextDay = new Date(startDate);
	let nextDayStr: string; // Declare nextDayStr variable
	do {
		nextDay.setDate(nextDay.getDate() + 1); // Increment the day
		nextDayStr = nextDay.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
	} while (
		nextDay.getDay() === 0 || // Sunday
		nextDay.getDay() === 6 || // Saturday
		arrayToCheckOverlap.some((holiday) => {
			const holidayStartStr = new Date(holiday.start_date).toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
			const holidayEndStr = new Date(holiday.end_date).toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
			return nextDayStr >= holidayStartStr && nextDayStr <= holidayEndStr;
		})
	);
	return nextDay.toISOString().split('T')[0] + 'T00:00:00Z';
}

@Component({
	selector: 'sp-set-schedule',
	templateUrl: './set-schedule.component.html',
	styleUrls: ['./set-schedule.component.scss'],
})
export class SetScheduleComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('scrollContainer', { read: ElementRef, static: false }) scrollContainer: ElementRef;
	@ViewChild('dialogContent', { static: true }) dialogContent!: ElementRef;
	@Input() allSchedules: ScheduleForDatesResponse;
	@Input() scheduleData: ScheduleGroupList[] = [];
	@Input() scheduleOnboardingStatus: OnboardingStatus;
	@Input() date: string; // should be in format 'YYYY-MM-DD'
	@Input() dateOfSetSchedule: string; // should be in format 'YYYY-MM-DD'
	@Input() holidaysDateObjects: { start_date: string; end_date: string }[];

	// if this exists we will use it to display the data for this scheduleID,
	// otherwise we'll default to the first schedule in the array
	@Input() selectedScheduleGroupId: number;
	@Input() selectedBellScheduleId: number;
	@Input() selectedTabIndex = 0;

	// this emits to update button state in dynamic dialog modal
	@Output() onChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() selectedData: EventEmitter<UpdateBellScheduleForDateReq> = new EventEmitter<UpdateBellScheduleForDateReq>();
	@Output() selectScheduleGroup: EventEmitter<number> = new EventEmitter<number>();
	// this displays in the left pane
	public selectedScheduleGroup: ScheduleGroupList;
	// this used to know what BellScheduleGroup is selected
	public selectedBellScheduleGroup: BellScheduleGroup;
	// this displays periods groupings in the right pane based on what BellScheduleGroup is selected
	public selectedBellSchedule: BellSchedule;
	public nextDayTypeInRotation: string;
	public nextDateInRotation: string;
	private bellScheduleGroups: BellScheduleGroup[];
	private bellSchedules: BellSchedule[];
	private isRotating = false;
	private initiallySelected: HTMLElement;

	private selectedScheduleIdx = 0;
	private selectedGroupIdx = 0;
	private selectedBellScheduleIdx = 0;

	public ngOnInit(): void {
		this.onSelectItem(0, 0, 0);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!isEqual(changes.scheduleData?.previousValue, changes.scheduleData?.currentValue)) {
			this.onSelectItem(0, 0, 0);
		}
		if (changes.allSchedules?.currentValue && changes.date?.currentValue && changes.scheduleData?.currentValue) {
			this.isRotating = this.scheduleData[this.selectedTabIndex].rotation_type === 'rotating';
		}
	}

	public ngAfterViewInit(): void {
		this.initiallySelected = document.getElementById('initially-selected');
		if (this.initiallySelected) {
			this.initiallySelected.scrollIntoView();
		}
	}

	public onSelectSchedule(selectedIndex: number): void {
		this.selectedScheduleGroupId = this.scheduleData[selectedIndex].id;
		this.selectScheduleGroup.emit(this.selectedScheduleGroupId);

		this.onSelectItem(selectedIndex, 0, 0);
	}

	public onSelectItem(scheduleIdx: number, groupIdx: number, bellScheduleIdx: number): void {
		this.selectedScheduleIdx = scheduleIdx;
		this.selectedGroupIdx = groupIdx;
		this.selectedBellScheduleIdx = bellScheduleIdx;

		this.selectedScheduleGroup = this.scheduleData ? this.scheduleData[this.selectedScheduleIdx] : null;
		this.selectedBellScheduleGroup = this.selectedScheduleGroup ? this.selectedScheduleGroup.bell_schedule_groups[this.selectedGroupIdx] : null;
		this.selectedBellSchedule = this.selectedBellScheduleGroup ? this.selectedBellScheduleGroup?.bell_schedules[this.selectedBellScheduleIdx] : null;
	}

	public isSelected(scheduleIdx: number, groupIdx: number, bellScheduleIdx: number): boolean {
		return this.selectedScheduleIdx === scheduleIdx && this.selectedGroupIdx === groupIdx && this.selectedBellScheduleIdx === bellScheduleIdx;
	}

	public onOpenHolidayModal(): void {
		// todo
		console.log('onOpenHolidayModal');
	}

	private getNextDayInRotation(selectedBellScheduleId: number): string | undefined {
		let nextBSId: number;
		for (const bsg of this.bellScheduleGroups) {
			const selected = bsg.bell_schedules.find((bs) => bs.id === selectedBellScheduleId);
			if (selected) {
				nextBSId = selected.next_regular_bs_id;
				break;
			}
		}
		let longName: string;
		for (const bsg of this.bellScheduleGroups) {
			const next = bsg.bell_schedules.find((bs) => bs.id === nextBSId);
			if (next) {
				longName = next.long_name;
				break;
			}
		}
		return longName;
	}

	private getAssignable(periods: PeriodTime[]): PeriodTime[] {
		return periods.filter((p) => p.is_assignable);
	}
}
