<div *ngIf="scheduleData && scheduleData.length > 0">
	<ng-container *ngIf="scheduleData.length > 1; else noTabs">
		<mat-tab-group
			(selectedIndexChange)="onSelectSchedule($event)"
			[selectedIndex]="selectedTabIndex"
			class="mat-tab-group"
			disableRipple
			id="schedule-grades-tab-group">
			<mat-tab *ngFor="let s of scheduleData; let index = index">
				<ng-template mat-tab-label>
					<div class="tab-label tw-font-medium">{{ s.name }}</div>
				</ng-template>

				<!-- MAT TAB CONTENT -->
				<ng-container [ngTemplateOutletContext]="{ schedule: s, index: index }" [ngTemplateOutlet]="scheduleContent"></ng-container>
				<!-- END MAT TAB CONTENT -->
			</mat-tab>
		</mat-tab-group>
	</ng-container>

	<ng-template #noTabs>
		<ng-container [ngTemplateOutletContext]="{ schedule: selectedScheduleGroup, index: 0 }" [ngTemplateOutlet]="scheduleContent"></ng-container>
	</ng-template>
	<ng-template #scheduleContent let-s="schedule" let-scheduleIndex="index">
		<div
			class="set-schedule-wrapper tw-flex tw-mt-5 tw-border tw-border-solid tw-border-gray-300 tw-text-gray-500 tw-rounded-xl tw-overflow-hidden tw-box-border">
			<!-- LEFT PANE -->
			<div
				#scrollContainer
				class="tw-bg-gray-050 set-schedule-left-pane tw-border-r tw-border-solid tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-box-border tw-overflow-auto">
				<ng-container *ngFor="let group of s.bell_schedule_groups; let last = last; let groupIndex = index">
					<div [ngClass]="{ 'tw-mb-9': !last }">
						<!-- GROUP NAME = 'Regular', 'early dismissal', etc -->
						<div
							[ngClass]="{
								'tw-text-navy-500': groupIndex === selectedGroupIndex,
								'tw-text-gray-400': groupIndex !== selectedGroupIndex
							}"
							class="tw-font-medium tw-text-xs tw-ml-2 group-name">
							{{ group.name }}
						</div>

						<!-- DAY TYPE NAME = 'A Day', 'B Day,', etc -->
						<ng-container *ngFor="let bs of group.bell_schedules; let bsIndex = index">
							<div
								(click)="onSelectItem(scheduleIndex, groupIndex, bsIndex)"
								[ngClass]="{
									'tw-text-white tw-bg-navy-500': isSelected(scheduleIndex, groupIndex, bsIndex),
									'tw-text-gray-500 hover:tw-bg-gray-150': !isSelected(scheduleIndex, groupIndex, bsIndex)
								}"
								class="day-type-name tw-font-medium tw-text-base tw-rounded-lg tw-mb-1 tw-flex tw-gap-2 tw-whitespace-nowrap"
								id="{{ isSelected(scheduleIndex, groupIndex, bsIndex) ? 'initially-selected' : 'bs-' + bsIndex }}">
								<div>{{ bs.long_name }}</div>

								<div *ngIf="bs.no_school" class="tw-flex-grow tw-relative">
									<img
										[ngClass]="{ selected: isSelected(scheduleIndex, groupIndex, bsIndex) }"
										[src]="
											index === selectedBellScheduleIdx ? './assets/Beach Umbrella-selected.svg' : ('./assets/Beach Umbrella.svg' | resolveAsset)
										"
										alt="umbrella icon"
										class="tw-absolute" />
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
			<!-- END LEFT PANE -->

			<!-- RIGHT PANE -->
			<div *ngIf="selectedBellScheduleGroup" class="set-schedule-right-pane tw-flex-grow tw-box-border tw-overflow-auto tw-h-full">
				<div class="tw-flex-col tw-flex tw-items-center tw-h-full tw-text-center">
					<!-- TODO this "holiday" should not be hard coded - maybe rely on period_groupings being empty array? -->
					<ng-container *ngIf="selectedBellSchedule?.name && selectedBellSchedule.name !== 'Holiday'; else noSchoolAddHoliday">
						<ng-container *ngIf="selectedBellSchedule?.period_bubbles.length > 0; else noPeriods">
							<div class="tw-text-navy-500 tw-text-base tw-font-medium tw-px-2">{{ selectedBellSchedule?.long_name }}</div>

							<div class="tw-mt-3 tw-flex-col tw-flex tw-justify-center tw-items-center">
								<ng-container *ngFor="let bubble of selectedBellSchedule?.period_bubbles; let bubbleIdx = index">
									<div
										*ngIf="
											bubble.period_groupings.length > 1 &&
											(bubbleIdx === 0 ? true : selectedBellSchedule[bubbleIdx - 1]?.period_bubbles?.length <= 1)
										"
										class="tw-font-normal tw-text-sm tw-text-gray-500">
										---
									</div>
									<div class="tw-font-normal tw-text-sm tw-text-gray-500">
										{{ bubble.long_name }}: {{ bubble.start_time | militaryTimeString }} - {{ bubble.end_time | militaryTimeString }}
									</div>
									<ng-container *ngIf="bubble.period_groupings.length > 1">
										<ng-container *ngFor="let grouping of bubble.period_groupings">
											<div class="tw-font-normal tw-text-sm tw-text-gray-500">
												{{ grouping.name }}:
												<ng-container *ngFor="let period of getAssignable(grouping.periods); let last = last">
													{{ period.start_time | militaryTimeString }} - {{ period.end_time | militaryTimeString }}<span *ngIf="!last">, </span>
												</ng-container>
												<div *ngFor="let period of grouping.periods" class="tw-font-italic tw-text-sm tw-text-gray-400">
													<{{ period.name }}: {{ period.start_time | militaryTimeString }} - {{ period.end_time | militaryTimeString }}>
												</div>
											</div>
										</ng-container>
										<div class="tw-font-normal tw-text-sm tw-text-gray-500">---</div>
									</ng-container>
								</ng-container>
							</div>
						</ng-container>

						<ng-template #noPeriods>
							<sp-no-periods [selectedBellSchedule]="selectedBellSchedule"></sp-no-periods>
						</ng-template>
					</ng-container>

					<div
						*ngIf="
							nextDayTypeInRotation && this.initiallySelectedBellScheduleId !== this.selectedBellSchedule.id && scheduleOnboardingStatus === 'active'
						"
						class="tw-text-gray-400 tw-text-center tw-text-sm tw-font-normal tw-mt-10">
						This will also set {{ nextDateInRotation | date : 'MMM d, yyyy' : 'UTC' }} to a {{ nextDayTypeInRotation }} and shift future days.
						<a class="tw-text-gray-400 tw-text-center tw-text-sm tw-font-normal" href="https://www.smartpass.app/shift-schedule" target="_blank"
							>Learn more</a
						>
					</div>
				</div>
			</div>
			<!-- END RIGHT PANE -->
		</div>
	</ng-template>
</div>
<ng-template #noSchoolAddHoliday>
	<div class="no-school-holiday tw-flex-col tw-flex tw-justify-center tw-items-center">
		<div class="tw-mb-2"><img [src]="'./assets/Beach Umbrella.svg' | resolveAsset" alt="umbrella icon" /></div>
		<div class="tw-text-navy-500 tw-text-base tw-font-bold tw-text-center">Add a Holiday to Set as No School</div>
		<div class="new-holiday-btn tw-mt-3">
			<app-gradient-button
				(click)="onOpenHolidayModal()"
				[border]="'1px solid #D4D9E2'"
				[customBackground]="'#FFFFFF'"
				[customShadow]="'0px 2px 10px 0px rgba(16, 20, 24, 0.10)'"
				[leftIcon]="'./assets/Plus (Gray-500).svg'"
				[textColor]="'#7083A0'"
				>New Holiday
			</app-gradient-button>
		</div>
	</div>
</ng-template>
