import { ApplicationRef, Component, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, HostListener, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatTabsModule } from '@angular/material/tabs';

import { TimeZoneService } from '../services/time-zone.service';
import { MilitaryTimeStringPipe } from '../pipes/military-time-string.pipe';
import { SelectedBellSchedulePipe } from '../pipes/selected-bell-schedule.pipe';
import { SetScheduleComponent } from './set-schedule/set-schedule.component';
import type { RotationType, Schedule, ScheduleForDatesResponse, ScheduleGroupList } from 'app/models/Schedule';
import { importPayload } from './data';
import { chain } from 'lodash';
import { ResolveAssetPipe } from 'app/resolve-asset.pipe';
import { NoPeriodsComponent } from './set-schedule/no-periods/no-periods.component';

type OnboardingStatus = 'processing' | 'in review' | 'pending' | 'active';

const isRotationType = (rotation: string): rotation is RotationType => {
	switch (rotation) {
		case 'day_of_week':
		case 'rotating':
			return true;
		default:
			return false;
	}
};

const minStartTime = (items: { start_time: string }[]) => {
	return chain(items)
		.map(({ start_time }) => start_time)
		.min()
		.value();
};

const maxEndTime = (items: { end_time: string }[]) => {
	return chain(items)
		.map(({ end_time }) => end_time)
		.max()
		.value();
};

const exportPayloadToData = (
	payload: typeof importPayload
): {
	schedule: Schedule;
	scheduleData: ScheduleGroupList[];
} => {
	const scheduleGroups = chain(payload.bell_schedules)
		.groupBy('schedule_group_idx')
		.entries()
		.map(([idx, s]) => [parseInt(idx), s] as const)
		.map(([idx, schedules]) => {
			return {
				id: 0,
				name: payload.schedule.schedule_group_names[idx],
				bell_schedules: schedules.map(({ bell_schedule }, _i) => {
					return {
						id: 0,
						name: bell_schedule.name,
						long_name: bell_schedule.long_name,
						short_name: bell_schedule.short_name,
						period_bubbles: bell_schedule.period_bubbles.map((pb) => {
							const periodGroupings = chain(pb.period_groupings)
								.map((pg) => ({
									id: 0,
									...pg,
									start_time: minStartTime(pg.periods),
									end_time: maxEndTime(pg.periods),
								}))
								.sortBy((pg) => pg.start_time)
								.value();

							return {
								...pb,
								start_time: minStartTime(periodGroupings),
								end_time: maxEndTime(periodGroupings),
								period_groupings: periodGroupings,
							};
						}),
						next_regular_bs_id: -1,
					};
				}),
			};
		})
		.value();

	const scheduleData = scheduleGroups.map((g) => {
		const groupIdx = payload.schedule.schedule_group_names.indexOf(g.name);
		const rotation = payload.rotation_settings[groupIdx].type;
		return {
			name: g.name,
			bell_schedule_groups: chain(g.bell_schedules)
				.groupBy('name')
				.entries()
				.map(([name, schedules], _idx) => ({
					id: 0,
					name,
					bell_schedules: schedules,
				}))
				.value(),
			rotation_type: isRotationType(rotation) ? rotation : 'day_of_week',
		};
	});

	return {
		schedule: {
			name: payload.schedule.schedule_name,
			schedule_groups: scheduleGroups,
			school_id: 0,
			status: 'active',
			subterms: payload.schedule.subterms,
			term: payload.schedule.term,
		},
		scheduleData,
	};
};

@Component({
	selector: 'app-root',
	template: `<div style="padding: 10px; width: 600px;">
		<sp-set-schedule
			[allSchedules]="allSchedules"
			[scheduleOnboardingStatus]="onboardingStatus"
			[scheduleData]="scheduleData"
			[date]="date"
			[holidaysDateObjects]="holidaysDateObjects"></sp-set-schedule>
	</div>`,
})
class Wrapper {
	allSchedules: ScheduleForDatesResponse = { days: {} };
	scheduleData: ScheduleGroupList[];
	onboardingStatus: OnboardingStatus = 'active';

	date = '2024-08-01';
	dateOfSetSchedule = undefined;
	holidaysDateObjects: { start_date: string; end_date: string }[] = [];

	@HostListener('window:message', ['$event.data'])
	onImportPayload({ type, payload }: { type: string; payload: typeof importPayload }) {
		if (type === 'importPayload') {
			const { scheduleData } = exportPayloadToData(payload);
			this.scheduleData = scheduleData;
		}
	}

	constructor() {
		// window.payload = importPayload;
		// this.onImportPayload({ type: 'importPayload', payload: importPayload });
	}
}

@NgModule({
	imports: [BrowserModule, BrowserAnimationsModule, CommonModule, MatTabsModule],
	declarations: [Wrapper, MilitaryTimeStringPipe, SelectedBellSchedulePipe, SetScheduleComponent, ResolveAssetPipe, NoPeriodsComponent],
	providers: [{ provide: 'TimeZoneService', useClass: TimeZoneService }, { provide: APP_BASE_HREF, useValue: '/' }, MilitaryTimeStringPipe, DatePipe],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule implements DoBootstrap {
	ngDoBootstrap(app: ApplicationRef) {
		const root = document.createElement('app-root', {});
		root.className = 'app-root';
		root.style.flexGrow = '1';
		root.style.display = 'flex';
		root.style.flexDirection = 'column';
		root.style.justifyContent = 'center';
		root.style.alignItems = 'center';
		document.body.appendChild(root);

		app.bootstrap(Wrapper, root);
	}
}
